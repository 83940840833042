import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import judoLogo from '../images/logo.png';
import itaFlag from '../images/italy_flag.png';
import ukFlag from '../images/unitedkingdom_flag.png';
import '../css/NavBar.css';
import DropdownMenu from './DropdownMenu'
import { useCulture } from '../CultureContext';


const NavBar = () => {
    const { jsonData } = useCulture(); 
    const [currentLanguage, setCurrentLanguage] = useState(() => {
        return localStorage.getItem('culture') || 'en-UK';
    });

    const handleClickFlag = (language) => {
        setCurrentLanguage(language);
        localStorage.setItem('culture', language);
        window.location.reload();
    };


    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="logo-container">
                    <img src={judoLogo} alt="Logo" className="logo" />
                </div>
               
                
                <DropdownMenu />
                
                <div className="culture-flags">
                    <img src={itaFlag} alt="flag" onClick={(e) => handleClickFlag("it_IT")}/>
                    <img src={ukFlag} alt="flag" onClick={(e) => handleClickFlag("en_UK")}/>
                </div>
         
            </div>

        </nav>
    );
};

export default NavBar;
