import React, { useState, useEffect } from 'react';
import { Analytics } from "@vercel/analytics/react";
import '../css/Contact.css';
import waicon from '../images/whatsapp_icon.png';
import gmailicon from '../images/gmail_icon.png';
import fbicon from '../images/facebook_icon.png';
import igicon from '../images/instagram_icon.png';
import { useCulture } from '../CultureContext';

const ContactMe = () => {
  const { jsonData } = useCulture(); 

  const handleWAButtonClick = (event) => {
    event.preventDefault(); 
    const phoneNumber = jsonData ? jsonData.personalinfo.phonenumber : '';
    if (phoneNumber) {
        const whatsappURL = `https://wa.me/${phoneNumber}?text=Hello%21`;
        const whatsappAnchor = document.createElement('a');
        whatsappAnchor.setAttribute('href', whatsappURL);
        whatsappAnchor.setAttribute('target', '_blank');
        whatsappAnchor.click();
    } else {
        console.error('Whatsapp phone number is not available.');
    }
};

const handleGmailClick = (event) => {
  event.preventDefault(); 
  const recipientEmail = jsonData ? jsonData.personalinfo.email : '';
  if (recipientEmail) {
      const subject = encodeURIComponent('JudoWebSite');
      const mailtoURL = `mailto:${recipientEmail}?subject=${subject}`;
      const mailtoAnchor = document.createElement('a');
      mailtoAnchor.setAttribute('href', mailtoURL);
      mailtoAnchor.click();
  } else {
      console.error('Gmail recipient is not available.');
  }
};

const handleFBButtonClick = (event) => {
  event.preventDefault(); 
  const fb = jsonData ? jsonData.personalinfo.facebook : '';
  if (fb) {
      const fbAnchor = document.createElement('a');
      fbAnchor.setAttribute('href', fb);
      fbAnchor.setAttribute('target', '_blank');
      fbAnchor.click();
  } else {
      console.error('Facebook URL is not available.');
  }
};

const handleIGButtonClick = (event) => {
  event.preventDefault(); 
  const ig = jsonData ? jsonData.personalinfo.instagram : '';
  if (ig) {
      const igAnchor = document.createElement('a');
      igAnchor.setAttribute('href', ig);
      igAnchor.setAttribute('target', '_blank');
      igAnchor.click();
  } else {
      console.error('Instagram URL is not available.');
  }
};


const GoogleMap = () => (
  <div className="google-map-container">
    <iframe
        width="100%"
        height="400vh"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCaljwtKaUWCCoStafdktcFKWwozEltCgs&q=51+Woodland+Way%2C+Mitcham+CR4+2DZ">
    </iframe>
    </div>
);

const Socials = () => (
  <div className="social-buttons-container">
    <div className="social-button wa-button" onClick={handleWAButtonClick}>
        <img src={waicon} alt="WhatsApp" className="icon" />
        <span className="button-text">WhatsApp</span>
    </div>
    <div className="social-button gmail-button" onClick={handleGmailClick}>
        <img src={gmailicon} alt="Gmail" className="icon" />
        <span className="button-text">Gmail</span>
    </div>
    <div className="social-button ig-button" onClick={handleIGButtonClick}>
        <img src={igicon} alt="Instagram" className="icon" />
        <span className="button-text">Instagram</span>
    </div>
    <div className="social-button fb-button" onClick={handleFBButtonClick}>
        <img src={fbicon} alt="Facebook" className="icon" />
        <span className="button-text">Facebook</span>
    </div>
  </div>
);


  return (
    <div className="contact-page">
       <Analytics />
       <GoogleMap />
       <Socials />
    </div>
  );
};

export default ContactMe;
