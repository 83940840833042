import React from 'react';
import '../css/Description.css';
import { Analytics } from "@vercel/analytics/react";
import { useCulture } from '../CultureContext';

const Description = () => {
    const { jsonData } = useCulture(); 

    return (
        <div>
            {jsonData ? ( 
                <div className="description">
                    <h1>{jsonData.description.title}</h1>
                    <p>{jsonData.description.text}</p>
                </div>
            ) : (
                <div>...</div>
            )}
        </div>
    );
};

export default Description;
