import React, { createContext, useState, useEffect, useContext } from 'react';

const CultureContext = createContext();
export const useCulture = () => useContext(CultureContext);

export const CultureProvider = ({ children }) => {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageCultureValue = localStorage.getItem("culture");
        if (localStorageCultureValue == null) { 
          const storedCulture = 'it_IT'; 
          const response = await import(`../public/culture/${storedCulture}.json`);
          setJsonData(response.default);
          localStorage.setItem("culture", storedCulture);
        }
        else { 
            const response = await import(`../public/culture/${localStorageCultureValue}.json`);
            setJsonData(response.default);
            localStorage.setItem("culture", localStorageCultureValue);
        }

       
      } catch (error) {
        console.error('Error fetching culture data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <CultureContext.Provider value={{ jsonData }}>
      {jsonData ? children : <div>...</div>} {}
    </CultureContext.Provider>
  );
};
