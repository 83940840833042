
import React, { useEffect, useState } from 'react';
import '../css/Cookie.css';  // Import the CSS file
import { useCulture } from '../CultureContext';

const Cookie = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { culture, jsonData } = useCulture(); // Access culture and jsonData

  // First useEffect: Check if it's the user's first visit
  useEffect(() => {
    if (!localStorage.getItem('visited')) {
      setIsVisible(true); // Show popup if it's the first visit
      localStorage.setItem('visited', 'true'); // Mark as visited
    }
  }, []);  // Empty dependency array, so it only runs on mount
  
  const handleClose = () => {
    setIsVisible(false); // Hide the popup
  };

  if (!isVisible) return null; // Don't render anything if popup is not visible

  return (
    <div className="cookie-popup">
      <div className="cookie-popup-content">
        <p>{jsonData?.cookie_message}</p>
        <button onClick={handleClose} className="cookie-popup-button">
          Ok
        </button>
      </div>
    </div>
  );
};

export default Cookie;
