import React, { useState, useEffect } from 'react';
import '../css/Courses.css';
import { useCulture } from '../CultureContext';
import piccoletigri from '../images/piccoletigri.jpeg';
import granditigri from '../images/granditigri.jpeg';
import giraffa from '../images/giraffa.jpeg';

const Courses = () => {
    const { jsonData } = useCulture(); 
    const courseImages = {
        "piccoletigri.jpeg": piccoletigri,
        "granditigri.jpeg": granditigri,
        "giraffa.jpeg": giraffa,
        // Add more mappings as needed
      };

    // Group courses by coach value
    const coursesByCoach = {};
    if (jsonData && jsonData.courses) {
        jsonData.courses.forEach((course) => {
            const coachValue = course.coach.value;
            if (!coursesByCoach[coachValue]) {
                coursesByCoach[coachValue] = [];
            }
            coursesByCoach[coachValue].push(course);
        });
    }

    return (
        <div className="courses-page">
            {jsonData ? (
                <div className="cards-container">
                    {Object.keys(coursesByCoach).map((coachValue) => (
                        <div key={coachValue} className="card">
                            <div className="course-header">
                                <h2 className="coach-name">{coachValue}</h2>
                              
                                {coursesByCoach[coachValue].some(course => {
                                    const imagePlaceholder = course.image_placeholder;
                                    return courseImages[imagePlaceholder];
                                }) && (
                                    <div
                                    className="course-image"
                                    style={{
                                        backgroundImage: `url(${courseImages[coursesByCoach[coachValue][0].image_placeholder]})`,
                                    }}
                                    key={coachValue}
                                    ></div>
                                )}

                            </div>
                                {coursesByCoach[coachValue].map((course, index) => (
                                    <div key={index} className="course">
                                        <h3>{course.title}</h3>
                                        <p className="description">{course.description}</p>
                                        <div className="details">
                                        {course.price.value !== "" && (
                                            <p className="price">
                                                <span className="label">
                                                    <strong>{course.price.title}:</strong> {course.price.value}
                                                </span>
                                            </p>
                                        )}
                                        {course.hours.value !== "" && (
                                            <p className="hours">
                                                <span className="label">
                                                    <strong>{course.hours.title}:</strong> {course.hours.value}
                                                </span>
                                            </p>
                                        )}
                                        {course.days.value !== "" && (
                                            <p className="days">
                                                <span className="label">
                                                    <strong>{course.days.title}:</strong> {course.days.value}
                                                </span>
                                            </p>
                                        )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                    ))}
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default Courses;
