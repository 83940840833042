import React, { useState, useEffect, useRef } from 'react';
import { useCulture } from '../CultureContext';
import '../css/NewsItems.css';

const NewsItem = ({ title, description, datetime }) => (
  <div className="news-item">
    <h2 className="news-item-title">{title}</h2>
    <p className="news-item-content">{description}</p>
    <small className="news-item-date">{new Date(datetime).toLocaleDateString()}</small>
  </div>
);

const NewsFeed = () => {
  const { jsonData } = useCulture();
  const newsFeedContainerRef = useRef(null);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('down'); // Tracks scroll direction

  // Check if the content is scrollable
  useEffect(() => {
    const checkIfScrollable = () => {
      const container = newsFeedContainerRef.current;
      if (container) {
        // Check if content is scrollable (i.e., content height > container height)
        const isScrollable = container.scrollHeight > container.clientHeight;
        setShowScrollArrow(isScrollable);
      }
    };

    checkIfScrollable();
    window.addEventListener('resize', checkIfScrollable); // Recheck on resize

    return () => {
      window.removeEventListener('resize', checkIfScrollable);
    };
  }, [jsonData?.newsItems]); // Run when newsItems change

  // Handle scroll direction change
  const handleScroll = () => {
    const container = newsFeedContainerRef.current;
    if (container) {
      if (container.scrollTop === 0) {
        setScrollDirection('down'); // At the top, point down
      } else if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        setScrollDirection('up'); // At the bottom, point up
      } else {
        setScrollDirection('down'); // In the middle, continue pointing down
      }
    }
  };

  // Add event listener for scroll events
  useEffect(() => {
    const container = newsFeedContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      const container = newsFeedContainerRef.current;
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="news-feed-container" ref={newsFeedContainerRef}>
      {/* Conditionally render the scroll arrow if scrollable */}
      {showScrollArrow && (
        <div className={`scroll-arrow ${scrollDirection === 'up' ? 'up' : 'down'}`}>
          {scrollDirection === 'down' ? '↓' : '↑'} {/* Arrow pointing down or up */}
        </div>
      )}

      {/* Render news items */}
      {jsonData?.newsItems?.map((newsItem, index) => (
        <NewsItem
          key={index}
          title={newsItem.title}
          description={newsItem.description}
          datetime={newsItem.datetime}
        />
      ))}
    </div>
  );
};

export default NewsFeed;
