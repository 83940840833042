// Home.js
import { Analytics } from "@vercel/analytics/react";
import React from 'react';
import Slider from './Slider';
import Description from './Description';
import '../css/App.css';

import pic1 from '../images/pic1.jpeg';
import pic2 from '../images/pic2.jpeg';
import pic3 from '../images/pic3.jpeg';
import pic4 from '../images/pic4.png';
import pic5 from '../images/pic5.jpeg';

function Home() {
    const images = [pic1,pic2,pic3,pic4,pic5];
    return (
        <div className="home">
            <Analytics />
            <Slider images={images} />
            <Description />
        </div>
    );
}

export default Home;
