import React, { useState, useEffect } from 'react';
import '../css/Footer.css' 
import { useCulture } from '../CultureContext';

function Footer() {
    const { jsonData } = useCulture(); 

    return (
        <div>
             {jsonData ? (
                <div className="footer">
                    <p>&copy; {new Date().getFullYear()} Nippon Club London  | {jsonData.footer.developed_by} <a href="https://www.linkedin.com/in/marco-conti-aba1aa13a/" target="_blank" rel="noopener noreferrer">{jsonData.footer.developer_name}</a></p>
                </div>
                ) : (
                <div>Loading...</div>
            )}
        </div>

    );
}

export default Footer;
