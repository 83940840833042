import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import '../css/DropdownMenu.css';
import { useCulture } from '../CultureContext';

function DropdownMenu() {
    const { jsonData } = useCulture(); 
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

  return (
    <div>
     <button  className="menu-button" onClick={toggleMenu}>&#9776;</button>
        {isMenuOpen && (
                <div className="dropdown-menu">
                    <ul>
                        <li onClick={toggleMenu} ><NavLink exact to="/">{jsonData ? jsonData.navbar.home : ''}</NavLink></li>
                        <li onClick={toggleMenu} ><NavLink to="/courses">{jsonData ? jsonData.navbar.courses : ''}</NavLink></li>
                        <li onClick={toggleMenu} ><NavLink to="/contact">{jsonData ? jsonData.navbar.contact : ''}</NavLink></li>
                        <li onClick={toggleMenu} ><NavLink to="/newsitems">{jsonData ? jsonData.navbar.news : ''}</NavLink></li>
                    </ul>
                </div>
        )}
    </div>
  );
}

export default DropdownMenu;
