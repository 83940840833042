// Slider.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../css/Slider.css'; // Optional: style for the slider and modal
import { useCulture } from '../CultureContext';

Modal.setAppElement('#root'); // Accessibility setting for react-modal

const Slider = ({ images }) => {
  const { jsonData } = useCulture(); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };
  return (
    <div className="slider">
      {/* Display each image in the slider */}
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index + 1}`}
          className="slider-image"
          onClick={() => openModal(image)}
        />
      ))}

      {/* Modal to show the enlarged image */}
      {selectedImage && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Enlarged Image"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <img src={selectedImage} alt="Enlarged" className="enlarged-image" />
          <button onClick={closeModal} className="close-button">{jsonData.close_button}</button>
        </Modal>
      )}
    </div>
  );
};

export default Slider;
